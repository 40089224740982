/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import styles from "./styles.module.scss";
import { InputContainer } from "..";

interface Option {
  name: string;
  value: string;
}

interface SelectProps {
  value: string;
  options: Option[];
  label?: string;
  error?: string;
  onChange: (value: string) => void;
}

export const Select = ({ value, options, onChange, label, error }: SelectProps) => {
  return (
    <InputContainer
      label={label}
      error={error}
      render={(ID) => (
        <select
          id={ID}
          className={styles.select}
          value={value}
          onChange={({ target }) => onChange(target.value)}
        >
          {options.map((option) => {
            return (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
      )}
    />
  );
};
