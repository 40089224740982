/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import styles from "./styles.module.scss";

interface SpaceProps {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const Space = ({ size = "md" }: SpaceProps) => {
  return <div className={`${styles.space} ${styles[size]}`}></div>;
};
