/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { CSSProperties, ReactNode } from "react";
import styles from "./styles.module.scss";

type Size = "xs" | "sm" | "md" | "lg" | "xl";

interface GroupProps {
  gap?: Size;
  direction?: "row" | "column";
  justify?: "auto" | "space-between";
  children: ReactNode;
  style?: CSSProperties;
}

const gapSizeMap: Record<Size, string> = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
};

export const Group = (props: GroupProps) => {
  const { gap = "md", direction = "column", justify = "auto", children, style = {} } = props;
  const classNames = `${styles.group} ${gapSizeMap[gap]}`;
  const inlineStyles = { ...style, flexDirection: direction, justifyContent: justify };
  return (
    <div className={classNames} style={inlineStyles}>
      {children}
    </div>
  );
};
