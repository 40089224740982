/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { CSSProperties } from "react";
import styles from "./styles.module.scss";
import { InputContainer } from "../";

type Size = "sm" | "md";

const sizeMap: Record<Size, string> = {
  sm: styles.sm,
  md: styles.md,
};

interface TextInputProps {
  value: string;
  label?: string;
  error?: string;
  placeholder?: string;
  showErrorMsg?: boolean;
  size?: Size;
  style?: CSSProperties;
  onChange: (value: string) => void;
}

export const TextInput = (props: TextInputProps) => {
  const {
    value,
    label,
    error,
    showErrorMsg = true,
    placeholder,
    size = "md",
    style = {},
    onChange,
  } = props;
  const classNames = `${styles.input} ${sizeMap[size]} ${error && styles.hasError}`;
  return (
    <InputContainer
      label={label}
      error={showErrorMsg ? error : ""}
      render={(ID) => (
        <input
          id={ID}
          type="text"
          className={classNames}
          value={value}
          placeholder={placeholder}
          style={style}
          onChange={({ target }) => {
            onChange(target.value);
          }}
        />
      )}
    />
  );
};
