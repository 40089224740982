/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Button } from "components/Button";
import { Switch } from "components/Form";
import { useForm } from "hooks/use-form";
import { FleetData } from "types/provision";
import { Group, Item } from "components/Layout";
import { EditIcon } from "components/icons";

interface EditStepProps {
  onEdit: (data: FleetData) => void;
  data: FleetData;
}

export const EditStep = ({ onEdit, data }: EditStepProps) => {
  const { formValues, onChange, onSubmit } = useForm<FleetData>({
    defaultValues: data,
  });

  return (
    <form onSubmit={onSubmit(onEdit)}>
      <Group>
        <Switch
          value={formValues.vehicleActivated}
          onChange={onChange("vehicleActivated")}
          label="Activated"
        />
        <Switch
          value={formValues.jlActivated}
          onChange={onChange("jlActivated")}
          label="GEO Location"
        />
        <Switch
          value={formValues.inControlDisabled}
          onChange={onChange("inControlDisabled")}
          label="Disable InControl"
        />
        <Item>
          <Button text="UPDATE" icon={<EditIcon />} />
        </Item>
      </Group>
    </form>
  );
};
