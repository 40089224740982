export const TrashIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17H13ZM15 9C15 8.44772 14.5523 8 14 8C13.4477 8 13 8.44772 13 9H15ZM15 17V9H13V17H15Z"
        fill="#0C121C"
      />
      <path
        d="M9 17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17H9ZM11 9C11 8.44772 10.5523 8 10 8C9.44772 8 9 8.44772 9 9H11ZM11 17V9H9V17H11Z"
        fill="#0C121C"
      />
      <path
        d="M5.875 21L4.8767 21.0582C4.90754 21.587 5.34534 22 5.875 22V21ZM18.125 21V22C18.6547 22 19.0925 21.587 19.1233 21.0582L18.125 21ZM19 6L19.9983 6.05823C20.0143 5.78337 19.9163 5.51406 19.7274 5.31379C19.5385 5.11352 19.2753 5 19 5V6ZM5 6V5C4.72467 5 4.46153 5.11352 4.2726 5.31379C4.08366 5.51406 3.98566 5.78337 4.0017 6.05823L5 6ZM5.875 22H18.125V20H5.875V22ZM19.1233 21.0582L19.9983 6.05823L18.0017 5.94177L17.1267 20.9418L19.1233 21.0582ZM19 5H5V7H19V5ZM4.0017 6.05823L4.8767 21.0582L6.8733 20.9418L5.9983 5.94177L4.0017 6.05823Z"
        fill="#0C121C"
      />
      <path d="M3 5H21" stroke="#0C121C" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M10 4L9.16795 3.4453L8.13148 5H10V4ZM10.6667 3V2C10.3323 2 10.0201 2.1671 9.83462 2.4453L10.6667 3ZM13.3333 3L14.1654 2.4453C13.9799 2.1671 13.6677 2 13.3333 2V3ZM14 4V5H15.8685L14.8321 3.4453L14 4ZM10.8321 4.5547L11.4987 3.5547L9.83462 2.4453L9.16795 3.4453L10.8321 4.5547ZM10.6667 4H13.3333V2H10.6667V4ZM12.5013 3.5547L13.1679 4.5547L14.8321 3.4453L14.1654 2.4453L12.5013 3.5547ZM14 3H10V5H14V3Z"
        fill="#0C121C"
      />
    </svg>
  );
};
