/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useEffect, useState } from "react";
import { PlusIcon } from "components/icons";
import { AmendFleetData } from "types/provision";
import { randomID } from "utils/random";
import { Group } from "components/Layout";
import { Button } from "components/Button";
import { VehicleRow } from "./VehicleRow";
import styles from "./styles.module.scss";
import { checkForDuplicateVin } from "./checkForDuplicateVin";
import { AmendType } from "../../AmendVehicles";

export interface FleetDataRow {
  id: string;
  isValid: boolean;
  data: AmendFleetData;
  formError: string;
}

const vehicleDataToVehicleRowData = (data: AmendFleetData) => {
  return { data, id: randomID(), isValid: false, formError: "" };
};

const emptyRow = () => {
  return vehicleDataToVehicleRowData({
    vin: "",
    jlActivated: false,
    inControlDisabled: false,
  });
};

interface ManualEntryStepProps {
  onComplete: (data: AmendFleetData[]) => void;
  onBack: (data: AmendFleetData[]) => void;
  initialData: AmendFleetData[];
  amendType: AmendType;
}

export const ManualEntryStep = (props: ManualEntryStepProps) => {
  const { onComplete, onBack, initialData, amendType } = props;

  const [rows, setRows] = useState<FleetDataRow[]>(() => {
    if (initialData.length) return initialData.map(vehicleDataToVehicleRowData);
    return [emptyRow()];
  });

  useEffect(() => {
    const foundDuplicate = checkForDuplicateVin(rows);
    if (foundDuplicate) {
      setRows((rows) =>
        rows.map((row, index) => {
          if (index === foundDuplicate)
            return { ...row, formError: "Duplicate VIN", isValid: false };
          else return row;
        })
      );
    }
  }, [rows]);

  const addVehicle = () => {
    setRows((rows) => [...rows, emptyRow()]);
  };

  const onRemove = (id: string) => {
    setRows((rows) => rows.filter((i) => i.id !== id));
  };

  const onChange = (id: string, rowData: AmendFleetData, isValid: boolean) => {
    setRows((rows) =>
      rows.map((row) => {
        if (row.id === id) return { id, isValid, data: rowData, formError: "" };
        return row;
      })
    );
  };

  const submit = () => {
    const allValid = rows.every(({ isValid }) => isValid);
    if (allValid) {
      onComplete(rows.map(({ data }) => data));
    }
  };

  return (
    <Group>
      <table className={`${styles.typyingTable} table clean`}>
        <thead>
          <tr>
            <th>VIN</th>
            {amendType !== "deactivate" && <th>GEO Location</th>}
            {amendType !== "deactivate" && <th>Disable InControl</th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <VehicleRow
              key={row.id}
              id={row.id}
              data={row.data}
              formError={row.formError}
              canRemove={rows.length > 1}
              onRemove={onRemove}
              onRowChange={onChange}
              amendType={amendType}
            />
          ))}
        </tbody>
      </table>

      <Group direction="row" justify="space-between">
        <Group direction="row" gap="sm">
          {rows.length < 5 && (
            <Button
              text="ADD MORE"
              onClick={addVehicle}
              icon={<PlusIcon />}
              disabled={!rows.every(({ isValid }) => isValid)}
            />
          )}
          <Button text="NEXT" disabled={!rows.every(({ isValid }) => isValid)} onClick={submit} />
        </Group>
        <Button text="BACK" ghost onClick={() => onBack(rows.map(({ data }) => data))} />
      </Group>
    </Group>
  );
};
