/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Link, NavLink } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "context/auth";
import styles from "./navbar.module.scss";
import { NavLogoutDropdown } from "./NavLogoutDropdown";
import { ToggleIcon } from "./ToggleIcon";

export function Navbar() {
  /* Check if user is logged in*/
  const { state } = useContext(AuthContext);
  /* 
		Close the toggle menu on mobile view 
		when a navItem is clicked or toggle between 
		the menu and close icon on click.
	*/
  const [isClicked, setIsClicked] = useState(false);
  function handleToggleClick(click: boolean) {
    setIsClicked(click);
  }

  return (
    <nav className={isClicked ? `${styles.navMenu} ${styles.active}` : `${styles.navMenu} `}>
      <Link to={state.isAuthenticated ? "/" : "/login"} className={styles.navLogo}>
        <img src="/logo.png" width="100%" alt="JLR logo" />
      </Link>

      <ToggleIcon isClicked={isClicked} handleToggleClick={handleToggleClick} />
      <ul className={isClicked ? `${styles.visible}` : ``} role="list">
        {state.isAuthenticated && (
          <>
            <li>
              <NavLink
                to="/"
                onClick={() => handleToggleClick(false)}
                className={({ isActive }) => (isActive ? styles.isActive : undefined)}
                role="link"
              >
                HOME
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/vehicles"
                onClick={() => handleToggleClick(false)}
                className={({ isActive }) => (isActive ? styles.isActive : undefined)}
                role="link"
              >
                VEHICLES
              </NavLink>
            </li>
          </>
        )}

        <li>
          <NavLink
            to="/help"
            onClick={() => handleToggleClick(false)}
            className={({ isActive }) => (isActive ? styles.isActive : undefined)}
            role="link"
          >
            HELP
          </NavLink>
        </li>

        {state.isAuthenticated && (
          <NavLogoutDropdown user={state.name} setIsClicked={setIsClicked} />
        )}
      </ul>
    </nav>
  );
}
