/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import styles from "./styles.module.scss";

interface DividerProps {
  size?: "none" | "xs" | "sm" | "md" | "lg" | "xl";
}

export const Divider = ({ size = "none" }: DividerProps) => {
  return (
    <div className={`${styles.container} ${styles[size]}`}>
      <div className={styles.line}></div>
    </div>
  );
};
