/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

export const dummyCVSData = [
  {
    vin: "TESTVIN1234567801",
    jlActivated: true,
    inControlDisabled: false,
    vehicleActivated: false,
  },
  { vin: "TESTVIN1234567802", jlActivated: true, inControlDisabled: true, vehicleActivated: false },
  {
    vin: "TESTVIN1234567803",
    jlActivated: false,
    inControlDisabled: false,
    vehicleActivated: false,
  },
];
