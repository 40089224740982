/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { FleetDataRow } from "./ManualEntryStep";

export const checkForDuplicateVin = (rows: FleetDataRow[]): false | number => {
  const filteredRows = rows
    .filter(({ data }) => data.vin.length > 1)
    .filter(({ isValid }) => isValid);

  if (filteredRows.length < 2) return false;

  for (let i = 1; i < filteredRows.length; i++) {
    const currentRow = filteredRows[i];
    const previousRows = filteredRows.slice(0, i);
    const foundDup = previousRows.find((row) => row.data.vin === currentRow.data.vin);
    if (foundDup) return i;
  }
  return false;
};
