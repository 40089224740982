/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Group, Item } from "components/Layout";
import { Button } from "components/Button";
import { Alert } from "components/Alert";
import { AmendVehiclesTable } from "../AmendVehiclesTable";

export interface ServerError {
  status: number;
  data: string[];
}

interface ServerErrorStepProps {
  serverError: ServerError;
  onRetry: () => void;
}

export const ServerErrorStep = ({ serverError, onRetry }: ServerErrorStepProps) => {
  if (serverError.status === 202) {
    const data = serverError.data.map((vin) => ({ vin }));
    return (
      <Group>
        <Alert
          type="error"
          body="Unfortunately we have not been able to process your request for the following VINs. Please click on the Retry button, if the problem persists please contact tech support: xxx-xxx-xxx"
        />
        <AmendVehiclesTable data={data} />
        <Button onClick={onRetry} />
      </Group>
    );
  } else {
    return (
      <Group>
        <Alert
          type="error"
          body="Unfortunately we have not been able to process your request at this time. Please click on the Retry button, if the problem persists please contact tech support: xxx-xxx-xxx"
        />
        <Item>
          <Button text="RETRY" onClick={onRetry} />
        </Item>
      </Group>
    );
  }
};
