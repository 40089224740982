/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { FleetData } from "types/provision";
import { parseBoolean, parseCSV, Parser } from "utils/parseCSV";

export const parseDeActivateCSV = (data: string[][]) => {
  type ParsedDeActivateCSV = Pick<FleetData, "vin">;
  const parseMap: Parser<ParsedDeActivateCSV>[] = [
    { key: "vin", resolver: (value: string) => value },
  ];
  return parseCSV<ParsedDeActivateCSV>(data, parseMap);
};

export const parseActivateCSV = (data: string[][]) => {
  type ParsedActivateCSV = Pick<FleetData, "vin" | "jlActivated" | "inControlDisabled">;
  const parseMap: Parser<ParsedActivateCSV>[] = [
    { key: "vin", resolver: (value: string) => value },
    { key: "jlActivated", resolver: parseBoolean },
    { key: "inControlDisabled", resolver: parseBoolean },
  ];
  return parseCSV<ParsedActivateCSV>(data, parseMap);
};
