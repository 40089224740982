/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Pagination } from "components/Table";
import { Group } from "components/Layout";
import { getSortIcon } from "./sort-icon";

interface Paginate {
  page: number;
  total: number;
  onChange: (page: number) => void;
}

interface TableProps<T> {
  data: T[];
  columns: Array<ColumnDef<T, string> | ColumnDef<T, boolean> | ColumnDef<T, unknown>>;
  paginate?: Paginate;
  initialPageSize?: number;
  onSort?: (state: SortingState) => void;
}

/**
 * A reusable table component meant for server-side data
 * with manual pagination and manual sorting
 */
export const Table = <T,>(props: TableProps<T>) => {
  const { data, columns, paginate, initialPageSize = 5, onSort } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const onHeaderClick = (id: string) => {
    let newState: SortingState = [];
    if (!sorting.length) {
      newState = [{ id, desc: true }];
    } else {
      if (id === sorting[0].id) {
        if (sorting[0].desc) {
          newState = [{ id, desc: false }];
        } else {
          newState = [];
        }
      } else {
        newState = [{ id, desc: true }];
      }
    }
    setSorting(newState);
    if (onSort) onSort(newState);
  };

  return (
    <Group gap="xs">
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div onClick={() => onHeaderClick(header.id)}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.accessorFn && getSortIcon(header.column.getIsSorted())}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {paginate && paginate.total > 1 && (
        <Pagination
          total={table.getPageCount()}
          page={table.getState().pagination.pageIndex}
          setPage={table.setPageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Group>
  );
};
