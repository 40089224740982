/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { IconUser } from "@tabler/icons";
import { useClickOutsideRef } from "hooks/use-click-outside-ref.hook";
import { useDeleteCookieInfo, utilities } from "hooks/use-cookie-info.hook";
import styles from "./navbar.module.scss";

type LogoutProps = {
  user: string;
  setIsClicked: (_: boolean) => void;
};

export function NavLogoutDropdown({ user, setIsClicked }: LogoutProps) {
  const { handleDropdownClick, buttonRef, isOpen } = useClickOutsideRef();
  const utils = utilities();
  const deleteCookieInfo = useDeleteCookieInfo(utils, setIsClicked);

  return (
    <>
      <li className={styles.logoutBtn}>
        <div ref={buttonRef}>
          <a role="button" aria-label="username" onClick={handleDropdownClick}>
            <IconUser /> &nbsp;
            {user}
          </a>
          {isOpen && (
            <a onClick={deleteCookieInfo} role="link" className={`btn ${styles.logoutDropdown}`}>
              LOG OUT
            </a>
          )}
        </div>
      </li>
    </>
  );
}
