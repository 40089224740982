/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useEffect } from "react";
import { TrashIcon } from "components/icons";
import { Switch, TextInput } from "components/Form";
import { useForm } from "hooks/use-form";
import { AmendFleetData } from "types/provision";
import styles from "./styles.module.scss";
import { AmendType } from "../../AmendVehicles";

interface VehicleRowProps {
  data: AmendFleetData;
  id: string;
  formError: string;
  canRemove?: boolean;
  onRemove?: (id: string) => void;
  onRowChange: (is: string, data: AmendFleetData, isValid: boolean) => void;
  amendType: AmendType;
}

const validations = {
  vin: { required: true, min: 17, max: 17 },
};

export const VehicleRow = (props: VehicleRowProps) => {
  const { data, id, formError, canRemove, onRemove, onRowChange, amendType } = props;

  const { formValues, errors, onChange, validate } = useForm<AmendFleetData>({
    defaultValues: data,
    validations,
  });

  useEffect(() => {
    onRowChange(id, formValues, validate());
  }, [formValues]);

  return (
    <tr className={formError || errors.vin ? styles.hasError : ""}>
      <td>
        <TextInput
          value={formValues.vin}
          onChange={onChange("vin")}
          placeholder="VIN"
          error={formError || errors.vin}
          size="sm"
          style={{ maxWidth: amendType === "deactivate" ? "50%" : 180 }}
        />
      </td>
      {amendType !== "deactivate" && (
        <>
          <td>
            <div className={styles.centerCell}>
              {formValues.jlActivated !== undefined && (
                <Switch value={formValues.jlActivated} onChange={onChange("jlActivated")} />
              )}
            </div>
          </td>
          <td>
            <div className={styles.centerCell}>
              {formValues.inControlDisabled !== undefined && (
                <Switch
                  value={formValues.inControlDisabled}
                  onChange={onChange("inControlDisabled")}
                />
              )}
            </div>
          </td>
        </>
      )}
      <td>
        {onRemove && canRemove && (
          <button onClick={() => onRemove(id)} className={styles.removeBtn} title="Remove Vehicle">
            <TrashIcon />
          </button>
        )}
      </td>
    </tr>
  );
};
