/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Group } from "components/Layout";
import { AmendVehicles } from "components/AmendVehicles";
import { PageLayout } from "components/PageLayout";
import { VehiclesTable } from "components/VehiclesTable";

export const Vehicles = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"activate" | "deactivate" | "update">("activate");
  const [modalTitle, setModalTitle] = useState("");

  return (
    <PageLayout
      header={
        <>
          <a href="#">Vehicles</a> &nbsp; &lt; &nbsp; Activate or Deactivate Vehicles
        </>
      }
    >
      <Group gap="sm">
        <Group direction="row" justify="space-between">
          <h3>Vehicles</h3>
          <Group gap="sm" direction="row">
            <Button
              text="Activate vehicles"
              testId="launchActivateVehicles"
              onClick={() => {
                setModalType("activate");
                setModalTitle("Activate Vehicles");
                setModalOpen(true);
              }}
            />

            <Button
              text="Amend vehicles"
              ghost
              testId="launchAmendVehicles"
              onClick={() => {
                setModalType("update");
                setModalTitle("Amend Vehicles");
                setModalOpen(true);
              }}
            />

            <Button
              text="Deactivate vehicles"
              testId="launchDeActivateVehicles"
              color="danger"
              onClick={() => {
                setModalType("deactivate");
                setModalTitle("DeActivate Vehicles");
                setModalOpen(true);
              }}
            />
          </Group>
        </Group>

        <VehiclesTable />
      </Group>

      <Modal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title={modalTitle}
        closeOnClickOutside={false}
      >
        <AmendVehicles amendType={modalType} />
      </Modal>
    </PageLayout>
  );
};
