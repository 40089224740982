/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { IconAlertTriangle, IconCheck, IconInfoCircle } from "@tabler/icons";
import styles from "./styles.module.scss";

const typeMap = {
  default: {
    style: "",
    icon: <IconInfoCircle aria-label="Alert Info" />,
  },
  success: {
    style: styles.success,
    icon: <IconCheck stroke={3} aria-label="Alert Success" />,
  },
  error: {
    style: styles.error,
    icon: <IconAlertTriangle stroke={2} aria-label="Alert Error" />,
  },
  warning: {
    style: styles.warning,
    icon: <IconAlertTriangle stroke={2} aria-label="Alert Warning" />,
  },
};

interface AlertProps {
  type: "default" | "success" | "error" | "warning";
  isOpen?: boolean;
  title?: string;
  body: string;
  classNames?: string;
}

export const Alert = (props: AlertProps) => {
  const { type, isOpen = true, title, body, classNames = "" } = props;
  const { style } = typeMap[type];

  if (!isOpen) return null;

  return (
    <div className={`${styles.container} ${style} ${classNames}`} role="alert">
      {/* {icon && <div className={styles.icon}>{icon}</div>} */}
      <div className={styles.body}>
        {title && <h4 className={styles.title}>{title}</h4>}
        <p className={styles.bodyText}>{body}</p>
      </div>
    </div>
  );
};
