/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ChangeEvent, FormEvent, useState } from "react";
import { useNotification } from "hooks/use-notification";
import { Divider } from "components/Layout";
import { PageLayout } from "components/PageLayout";
import { Accordion } from "components/Accordion/Accordion";
import { QueryInputBox } from "./QueryInputBox";
import styles from "./help.module.scss";

const STATUS = {
  IDLE: "IDLE",
  SUBMITTED: "SUBMITTED",
  SUBMITTING: "SUBMITTING",
  COMPLETED: "COMPLETED",
};

const defaultData = {
  title: "",
  fname: "",
  surname: "",
  email: "",
  company: "",
  query: "",
  feedback: "",
};

export function Help() {
  const [data, setData] = useState(defaultData);
  const [status, setStatus] = useState(STATUS.IDLE);
  const { notify } = useNotification();

  function handleChange(
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) {
    setData((curData) => {
      return {
        ...curData,
        [event.target.id]: event.target.value,
      };
    });
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    setStatus(STATUS.SUBMITTING);
    // TODO: send data to backend service.
    notify({ type: "success", title: "Thanks for your submission" });
    setStatus(STATUS.COMPLETED);
    setData(defaultData);
  }

  return (
    <PageLayout>
      {/* FAQs Section */}
      <section>
        <h3>{"FAQ'S:"}</h3>
        <p className={styles.paragraph}>
          Do you have a common problem? Hopefully our FAQ section can help.
        </p>

        <div className={styles.accordion}>
          <Accordion
            title="Unable to login with your password?"
            content={`Please double check your details, and speak to your IT department\
						to validate whether the correct password has been provided to you.\
						Please check your spam folder and inbox to validate whether any account\
						activation emails have been issued. Failing that submit the form\
						below and we will be in touch.`}
          />
          <Accordion
            title="Unable to login due to a time out?"
            content={`Please double check your details, and speak to your IT department\
						to validate whether the correct password has been provided to you.\
						Please check your spam folder and inbox to validate whether any account\
						activation emails have been issued. Failing that submit the form\
						below and we will be in touch.`}
          />
        </div>
      </section>

      <Divider size="xl" />

      {/* Query Section */}
      <section>
        <h3>QUERY:</h3>
        <p className={styles.paragraph}>
          Have a query? Let us know below with the form fields provided.
        </p>
        <form onSubmit={handleSubmit}>
          {/* Personal Information */}
          <section className={styles.formSection}>
            <h3>PERSONAL INFORMATION:</h3>
            <div className={styles.formDropdown}>
              <select
                id="title"
                role="title" // For testing
                className={`form-select ${styles.formControl}`}
                defaultValue=""
                onChange={handleChange}
                required
              >
                <option value="">Select Title</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Miss.">Miss.</option>
                <option value="Mr.">Mr.</option>
              </select>
              <label className={styles.formLabel} htmlFor="title">
                Title
              </label>
            </div>

            <QueryInputBox
              id="fname"
              value={data.fname}
              onChange={handleChange}
              label="First Name"
            />
            <QueryInputBox
              id="surname"
              value={data.surname}
              onChange={handleChange}
              label="Surname"
            />
            <QueryInputBox
              id="email"
              value={data.email}
              onChange={handleChange}
              label="Email Address"
              type="email"
            />
            <QueryInputBox
              id="company"
              value={data.company}
              onChange={handleChange}
              label="Company"
            />
          </section>

          {/* How Can We Help You? */}
          <section className={styles.formSection}>
            <h3>HOW CAN WE HELP YOU?</h3>
            <div className={styles.formDropdown}>
              <select
                id="query"
                role="query" // for testing
                className={`form-select ${styles.formControl}`}
                defaultValue=""
                onChange={handleChange}
                required
              >
                <option value="">Select A Query</option>
                <option value="General Enquiry">General Enquiry</option>
                <option value="Raising An Issue">Raising An Issue</option>
                <option value="Error Encountered">Error Encountered</option>
              </select>
              <label className={styles.formLabel} htmlFor="query">
                Query
              </label>
            </div>
          </section>

          {/* Any Comments? */}
          <section className={styles.formFeedback}>
            <h3>ANY COMMENTS?</h3>
            <div className={styles.formItem}>
              <textarea
                className={`form-control ${styles.formControl}`}
                id="feedback"
                role="feedback" // for testing
                value={data.feedback}
                onChange={handleChange}
                rows={3}
                required
              ></textarea>
              <label className={styles.formLabel} htmlFor="feedback">
                Feedback
              </label>
            </div>
          </section>

          <button
            type="submit"
            role="submit"
            className="btn"
            disabled={status === STATUS.COMPLETED}
          >
            <div className="text">SUBMIT</div>
          </button>
        </form>
      </section>
    </PageLayout>
  );
}
