/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Group } from "components/Layout";
import { SuccessIcon } from "components/AnimatedIcons";

export const SuccessStep = () => {
  return (
    <Group>
      <p>Successfully requested vehicle edit.</p>
      <SuccessIcon />
    </Group>
  );
};
