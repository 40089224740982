/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { IconMenu2, IconX } from "@tabler/icons";
import styles from "./navbar.module.scss";

type ToggleProps = {
  isClicked: boolean;
  handleToggleClick: (value: boolean) => void;
};

export function ToggleIcon({ isClicked, handleToggleClick }: ToggleProps) {
  return (
    <a role="button" className={styles.toggleMenu} onClick={() => handleToggleClick(!isClicked)}>
      {isClicked ? <IconX aria-label="x-icon" /> : <IconMenu2 aria-label="list-icon" />}
    </a>
  );
}
