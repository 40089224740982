/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useContext } from "react";
import { Group } from "components/Layout";
import { AuthContext } from "context/auth";
import { VehiclesTable } from "components/VehiclesTable";
import { PageLayout } from "components/PageLayout";

export function Home() {
  const { state } = useContext(AuthContext);

  return (
    <PageLayout>
      <Group gap="xs">
        <h4>
          Welcome {state.name} | {state.role}
        </h4>
        <h3>My Vehicles</h3>
        <VehiclesTable />
      </Group>
    </PageLayout>
  );
}
