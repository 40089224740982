export const PlusIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 11H13V1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1V11H1C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13H11V23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11Z"
        fill="white"
      />
      <mask id="mask0_616_2871" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 11H13V1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1V11H1C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13H11V23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_616_2871)"></g>
    </svg>
  );
};
