/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { CSSProperties, ReactNode } from "react";

interface ItemProps {
  flex?: number;
  style?: CSSProperties;
  children?: ReactNode;
}

export const Item = ({ flex = 1, style = {}, children }: ItemProps) => {
  const inlineStyle = { ...style, flex };
  return <div style={inlineStyle}>{children}</div>;
};
