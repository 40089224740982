/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { EditIcon } from "components/icons";
import { FleetData } from "types/provision";
import { Modal } from "components/Modal";
import { NoData, Table } from "components/Table";
import { EditVehicle } from "components/EditVehicle";
import { Switch } from "components/Form";
import { Group } from "components/Layout";
import { dummyData } from "utils/dummyData";
import { VehiclesTableFilters } from "./VehiclesTableFilters";
//import { Spinner } from "components/Spinner";

const { accessor, display } = createColumnHelper<FleetData>();

export const VehiclesTable = () => {
  const perPage = 8;
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [vehicleToEdit, setVehicleToEdit] = useState<FleetData>();
  const [modalTitle, setModalTitle] = useState("");
  //const [filter, setFilter] = useState<Filter>({ jlActivated: true, inControlDisabled: false });

  const [vehicles] = useState<FleetData[]>(dummyData.slice(0, perPage));

  const onRowClick = (rowIndex: number) => {
    setVehicleToEdit(vehicles[rowIndex]);
    setModalTitle(vehicles[rowIndex].vin);
    setModalOpen(true);
  };

  const onSort = (sort: { id: string; desc: boolean }[]) => {
    // TODO: Do server sort
    console.log(sort);
  };

  const columns = [
    accessor("vin", { header: "VIN" }),
    accessor("vehicleActivated", {
      header: "Activated",
      cell: (info) => (info.getValue() ? "Active" : "InActive"),
    }),
    accessor("jlActivated", {
      header: "GEO Location",
      cell(info) {
        return <Switch value={info.getValue()} onChange={() => ""} />;
      },
    }),
    accessor("inControlDisabled", {
      header: "Disable InControl",
      cell(info) {
        return <Switch value={info.getValue()} onChange={() => ""} />;
      },
    }),
    display({
      id: "amend",
      header: "Amend",
      cell: ({ row }) => {
        return (
          <span onClick={() => onRowClick(row.index)} className="pointer">
            <EditIcon />
          </span>
        );
      },
    }),
  ];

  // if (fetching) return <Spinner />;

  // if (error) return <TableError onRetry={reexecuteQuery} />;

  if (!vehicles.length)
    return (
      <NoData
        title="NO VEHICLES ADDED"
        body="Start by Activating Vehicles or Uploading a CSV file"
      />
    );

  return (
    <Group gap="sm">
      <VehiclesTableFilters onChange={console.log} />

      <Table
        data={vehicles}
        columns={columns}
        onSort={onSort}
        paginate={{ page, total: 2, onChange: setPage }}
      />

      <Modal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title={modalTitle}
        closeOnClickOutside={false}
      >
        <EditVehicle data={vehicleToEdit as FleetData} />
      </Modal>
    </Group>
  );
};
