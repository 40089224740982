/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Group, Item } from "components/Layout";
import { Button } from "components/Button";
import { Alert } from "components/Alert";

interface ServerErrorStepProps {
  onRetry: () => void;
  status: number;
}

export const ServerErrorStep = ({ onRetry }: ServerErrorStepProps) => {
  return (
    <Group>
      <Alert
        type="error"
        body="Unfortunately we have not been able to process your request for this VIN. Please click on the Retry button, if the problem persists please contact tech support: xxx-xxx-xxx"
      />
      <Item>
        <Button text="RETRY" onClick={onRetry} />
      </Item>
    </Group>
  );
};
