/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import styles from "./styles.module.scss";
import { InputContainer } from "../";

interface CheckboxProps {
  value: boolean;
  label?: string;
  onChange?: (value: boolean) => void;
}

export const Switch = ({ value, label, onChange }: CheckboxProps) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <InputContainer
        label={label}
        style={{ flexDirection: "row", gap: "2rem" }}
        render={(ID) => (
          <div
            className={styles.switch}
            data-focus={focus}
            data-checked={value}
            data-testid="switch"
            onClick={() => onChange && onChange(!value)}
          >
            <div className={styles.container}>
              <input
                type="checkbox"
                id={ID}
                checked={value}
                onChange={() => onChange && onChange(!value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={styles.hiddenInput}
              />
              <span className={styles.slider}></span>
            </div>
          </div>
        )}
      />
    </>
  );
};
