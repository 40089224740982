/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

export const dummyData = [
  { vin: "TESTVIN1234567891", jlActivated: true, inControlDisabled: false, vehicleActivated: true },
  { vin: "TESTVIN1234567892", jlActivated: true, inControlDisabled: false, vehicleActivated: true },
  { vin: "TESTVIN1234567893", jlActivated: true, inControlDisabled: false, vehicleActivated: true },
  { vin: "TESTVIN1234567894", jlActivated: true, inControlDisabled: false, vehicleActivated: true },
  { vin: "TESTVIN1234567895", jlActivated: true, inControlDisabled: true, vehicleActivated: false },
  { vin: "TESTVIN1234567896", jlActivated: true, inControlDisabled: true, vehicleActivated: true },
  { vin: "TESTVIN1234567897", jlActivated: true, inControlDisabled: true, vehicleActivated: true },
  { vin: "TESTVIN1234567898", jlActivated: true, inControlDisabled: false, vehicleActivated: true },
  { vin: "TESTVIN1234567111", jlActivated: true, inControlDisabled: true, vehicleActivated: true },
  {
    vin: "TESTVIN1234567112",
    jlActivated: true,
    inControlDisabled: false,
    vehicleActivated: false,
  },
  {
    vin: "TESTVIN1234567123",
    jlActivated: true,
    inControlDisabled: false,
    vehicleActivated: false,
  },
  { vin: "TESTVIN1234567124", jlActivated: true, inControlDisabled: true, vehicleActivated: true },
];
