/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { AmendFleetData } from "types/provision";
import { AutoTable } from "components/Table";
import { Switch } from "components/Form";

const { accessor, display } = createColumnHelper<AmendFleetData>();

interface AmendVehiclesTableProps {
  data: AmendFleetData[];
  activeColumns?: Array<keyof AmendFleetData>;
  textOnly?: boolean;
}

export const AmendVehiclesTable = (props: AmendVehiclesTableProps) => {
  const { data, activeColumns = [], textOnly = false } = props;

  const renderBooleanCell = (info: CellContext<AmendFleetData, boolean>) => {
    const value = info.getValue();

    if (textOnly) return value ? "ON" : "OFF";
    return <Switch value={value} />;
  };

  const columns = [
    display({
      id: "index",
      header: "",
      enableSorting: false,
      cell: ({ row }) => row.index + 1,
    }),
    accessor("vin", { header: "VIN" }),
    ...(activeColumns.includes("jlActivated")
      ? [
          accessor("jlActivated", {
            header: "GEO Location",
            cell: renderBooleanCell,
          }),
        ]
      : []),
    ...(activeColumns.includes("inControlDisabled")
      ? [
          accessor("inControlDisabled", {
            header: "Disable InControl",
            cell: renderBooleanCell,
          }),
        ]
      : []),
  ];

  return <AutoTable data={data} columns={columns} />;
};
