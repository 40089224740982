/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "context/auth";
import { getCookieInfo } from "hooks/use-cookie-info.hook";
import { Login } from "pages/Login";
import { Spinner } from "components/Spinner";
import { PageLayout } from "components/PageLayout";

type RouteProps = {
  children: ReactNode;
};

export const AuthRoute = ({ children }: RouteProps) => {
  const { state } = useContext(AuthContext);
  const { pathname } = useLocation();
  getCookieInfo();

  if (state.isLoading) {
    return (
      <PageLayout>
        <Spinner text="LOADING" />
      </PageLayout>
    );
  }

  if (!state.isAuthenticated) {
    return <Login />;
  }

  if (pathname === "/login") {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
