/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import React from "react";
import { createRoot } from "react-dom/client";
import "styles/app.global.scss";
import { AuthContextProvider } from "context/auth";
import { NotificationContextProvider } from "context/notification";
import { App } from "pages/App";
import { Notifications } from "components/Notifications";
import { reportWebVitals } from "./reportWebVitals";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <NotificationContextProvider>
        <App />
        <Notifications />
      </NotificationContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
