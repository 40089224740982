/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ReactQueryContextProvider } from "context/react-query";
import { AuthRoute } from "./AuthRoute";
import { Home } from "../Home";
import { Login } from "../Login";
import { ErrorFallback, InternalServerError, NotFoundError } from "../ErrorPage";
import { Help } from "../Help";
import { Vehicles } from "../Vehicles";
import { Navbar } from "./";

const routes = [
  { path: "/", element: <Home />, isAuth: true },
  { path: "/vehicles", element: <Vehicles />, isAuth: true },
  { path: "/login", element: <Login />, isAuth: true },
  { path: "/help", element: <Help /> },
  { path: "/500", element: <InternalServerError /> },
  { path: "*", element: <NotFoundError /> },
];

export const App = () => (
  <BrowserRouter>
    <ReactQueryContextProvider>
      <Navbar />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Routes>
          {routes.map(({ path, element, isAuth }) => (
            <Route
              key={path}
              path={path}
              element={isAuth ? <AuthRoute>{element}</AuthRoute> : element}
            />
          ))}
        </Routes>
      </ErrorBoundary>
    </ReactQueryContextProvider>
  </BrowserRouter>
);
