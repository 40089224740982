/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { createContext, ReactNode, useMemo, useState } from "react";

interface AuthContextProviderProps {
  children: ReactNode;
}

interface Auth {
  isAuthenticated: boolean;
  isLoading: boolean;
  name: string;
  role: string;
}

export interface Context {
  state: Auth;
  setState: (state: Auth) => void;
}

const ctxDefaultValue: Context = {
  state: { isAuthenticated: false, isLoading: false, name: "User", role: "Role" },
  setState: () => undefined,
};

export const AuthContext = createContext<Context>(ctxDefaultValue);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [state, setState] = useState(ctxDefaultValue.state);
  const value = useMemo(() => ({ state, setState }), [state]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
