/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { booleanRegEx, validateCSV, vinRegEx } from "utils/validateCSV";

export const validateDeActivateCSV = (data: string[][]) => {
  const deactivateValidationRules = [{ columnName: "VIN", regEx: vinRegEx }];
  return validateCSV(data, deactivateValidationRules);
};

export const validateActivateCSV = (data: string[][]) => {
  const activateValidationRules = [
    { columnName: "VIN", regEx: vinRegEx },
    { columnName: "Geo Location", regEx: booleanRegEx },
    { columnName: "Disable InControl", regEx: booleanRegEx },
  ];
  return validateCSV(data, activateValidationRules);
};
