/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import { useFetch } from "hooks/use-fetch";
import { FleetData } from "types/provision";
import { Steps } from "components/Steps";
import { Spinner } from "components/Spinner";
import { EditStep, ServerErrorStep, SuccessStep } from "./EditVehicleSteps";

type EditSteps = "EDIT" | "LOADING" | "ERROR" | "SUCCESS";

interface EditVehicleProps {
  data: FleetData;
}

export const EditVehicle = ({ data }: EditVehicleProps) => {
  const [step, setStep] = useState<EditSteps>("EDIT");
  const [formData, setFormData] = useState<FleetData>(data);
  const [error, setError] = useState({ status: 500 });
  const { amendVehicle } = useFetch();

  const onEdit = async (data: FleetData) => {
    setFormData(data);
    setStep("LOADING");
    try {
      const activateStaus = data.vehicleActivated ? "activate" : "deactivate";
      const { status } = await amendVehicle([data], activateStaus);
      if (status !== 200) {
        setStep("ERROR");
        setError({ status });
      } else {
        setStep("SUCCESS");
      }
    } catch (e) {
      setStep("ERROR");
      setError({ status: 500 });
    }
  };

  const GetCurrentStep = () => {
    switch (step) {
      case "EDIT":
        return <EditStep data={data} onEdit={onEdit} />;

      case "LOADING":
        return <Spinner text="PLEASE WAIT" />;

      case "ERROR":
        return <ServerErrorStep status={error.status} onRetry={() => onEdit(formData)} />;

      case "SUCCESS":
        return <SuccessStep />;
    }
  };

  return <Steps step={step}>{GetCurrentStep()}</Steps>;
};
