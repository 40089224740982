/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ReactNode, useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";

interface StepsProps {
  step: string;
  children: ReactNode;
}

export const Steps = ({ step, children }: StepsProps) => {
  const containerDiv = useRef(null);

  return (
    <SwitchTransition>
      <CSSTransition
        classNames="slide"
        timeout={500}
        nodeRef={containerDiv}
        key={step}
        unmountOnExit
      >
        <div ref={containerDiv}>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  );
};
