/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { IconCircleOff } from "@tabler/icons";
import styles from "./styles.module.scss";

interface NoDataProps {
  title: string;
  body?: string;
  show?: boolean;
}

export const NoData = ({ title, body, show = true }: NoDataProps) => {
  if (!show) return null;
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <IconCircleOff stroke={1} size={70} />
        <div>
          <h3>{title}</h3>
          {body && <p>{body}</p>}
        </div>
      </div>
    </div>
  );
};
