/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Link } from "react-router-dom";
import { Group, Item, Space } from "components/Layout";
import { PageLayout } from "components/PageLayout";

type ErrorType = {
  message?: string;
};

export function ErrorPage({ message = "AN ERROR HAS OCCURRED." }: ErrorType) {
  return (
    <PageLayout>
      <Group>
        <Item style={{ maxWidth: 500 }}>
          <h3>OOPS! {message}</h3>
          <Space />
          <Link to="/" role="button" className="btn">
            <div className="text">GO BACK</div>
          </Link>
        </Item>
      </Group>
    </PageLayout>
  );
}
