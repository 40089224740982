/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useCallback } from "react";
import { useFetch } from "hooks/use-fetch";
import { AmendFleetData, FleetData } from "types/provision";
import { AmendType } from "./AmendVehicles";

export const useAmendVehiclesApi = (amendType: AmendType) => {
  const { amendVehicle, get } = useFetch();

  const activateVehicleApi = useCallback((data: AmendFleetData[]) => {
    return amendVehicle(data, "activate");
  }, []);

  const deactivateVehicleApi = useCallback(async (data: AmendFleetData[]) => {
    const vehicles = await getAmendedVehiclesMissingFields(data);
    return amendVehicle(vehicles, "deactivate");
  }, []);

  const amendVehicleApi = async (amendData: AmendFleetData[]) => {
    const vehicles = await getAmendedVehiclesMissingFields(amendData);
    const vehiclesToActive = vehicles.filter((v) => v.vehicleActivated);
    const vehiclesToDeactive = vehicles.filter((v) => !v.vehicleActivated);
    const result = await Promise.all([
      amendVehicle(vehiclesToActive, "activate"),
      amendVehicle(vehiclesToDeactive, "deactivate"),
    ]);
    for (const { status, data } of result) {
      if (status !== 200) return { status, data };
    }
    return { status: 200, data: [] };
  };

  const getAmendedVehiclesMissingFields = async (amendVehicles: AmendFleetData[]) => {
    const vehicleVINs = amendVehicles.map(({ vin }) => vin).join(",");
    // TODO: Replace with actual end-point
    const { data: vehicles, status } = await get<FleetData[]>(
      `//GET_VEHICLES?vehicleIDs=${vehicleVINs}`
    );
    if (status !== 200) throw new Error("Error getting vehicles");
    return vehicles
      .map((vehicle) => {
        const findAmendVehicle = amendVehicles.find(({ vin }) => vin === vehicle.vin);
        if (!findAmendVehicle) return null;
        // Overwrite vehicle data from DB with data that user amended
        return { ...vehicle, ...findAmendVehicle };
      })
      .filter((vehicle) => vehicle) as FleetData[];
  };

  if (amendType === "activate") return activateVehicleApi;
  else if (amendType === "deactivate") return deactivateVehicleApi;
  else return amendVehicleApi;
};
