/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import { useNotification } from "hooks/use-notification";
import { Group } from "components/Layout";
import { Button } from "components/Button";
import { AmendFleetData } from "types/provision";
import { CSVUploader } from "components/CSVUploader";
import { Alert } from "components/Alert";
import { validateActivateCSV, validateDeActivateCSV } from "./validateCSV";
import { dummyCVSData } from "./dummyCSV";
import { parseActivateCSV, parseDeActivateCSV } from "./parseCSV";
import { AmendVehiclesTable } from "../AmendVehiclesTable";
import { AmendType } from "../../AmendVehicles";

interface CSVStepProps {
  onComplete: (data: AmendFleetData[]) => void;
  onBack: () => void;
  amendType: AmendType;
}

export const CSVStep = ({ onComplete, onBack, amendType }: CSVStepProps) => {
  const [error, setError] = useState("");
  const { notify } = useNotification();
  const validator = amendType === "deactivate" ? validateDeActivateCSV : validateActivateCSV;

  const parser = amendType === "deactivate" ? parseDeActivateCSV : parseActivateCSV;

  const onUpload = async (CSVData: string[][], error?: string) => {
    // Check if CSV contains column headers and remove them
    if (!error && CSVData[0][0].length !== 17) CSVData.shift();

    setError("");
    const errorMsg = validator(CSVData);
    if (error || errorMsg) {
      await new Promise((res) => setTimeout(res, 100));
      if (error) {
        setError("Please make sure your file is a valid CSV");
      } else {
        setError(errorMsg);
      }
    } else {
      onComplete(parser(CSVData));
      notify({
        type: "success",
        title: "SUCCESS",
        body: `${CSVData.length} rows uploaded`,
      });
    }
  };

  return (
    <Group>
      <Alert isOpen={!!error} type="error" body={`${error}`} classNames="shake" />
      <p>Upload a CSV file from your computer. The CSV format must look like below example.</p>
      <AmendVehiclesTable
        data={dummyCVSData}
        activeColumns={amendType === "deactivate" ? [] : ["jlActivated", "inControlDisabled"]}
        textOnly
      />
      <Group direction="row" justify="space-between">
        <CSVUploader onUpload={onUpload} />
        <Button text="BACK" ghost onClick={onBack} />
      </Group>
    </Group>
  );
};
