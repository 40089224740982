/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import styles from "./styles.module.scss";

interface SpinnerProps {
  show?: boolean;
  text?: string;
}

export const Spinner = ({ show = true, text = "PLEASE WAIT" }: SpinnerProps) => {
  if (!show) return null;

  return (
    <div className={styles.container}>
      <div className={styles.spinner} role="status">
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};
