/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Group } from "components/Layout";
import { AmendFleetData } from "types/provision";
import { Button } from "components/Button";
import { AmendVehiclesTable } from "../AmendVehiclesTable";
import { AmendType } from "../../AmendVehicles";

const verbMap = {
  activate: "Activation",
  deactivate: "DeActivation",
  update: "Amendment",
};

interface ConfirmStepProps {
  data: AmendFleetData[];
  onConfirm: () => void;
  onBack: () => void;
  amendType: AmendType;
}

export const ConfirmStep = ({ data, onConfirm, onBack, amendType }: ConfirmStepProps) => (
  <Group>
    <p>Please review your {verbMap[amendType]} request and confirm.</p>
    <AmendVehiclesTable
      data={data}
      activeColumns={amendType === "deactivate" ? [] : ["jlActivated", "inControlDisabled"]}
    />
    <Group direction="row" justify="space-between">
      <Button text="CONFIRM" onClick={onConfirm} />
      <Button text="BACK" ghost onClick={onBack} />
    </Group>
  </Group>
);
