/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { parse } from "papaparse";
import { ChangeEvent } from "react";

interface CSVUploaderProps {
  onUpload: (data: string[][], error?: string) => void;
}

export const CSVUploader = ({ onUpload }: CSVUploaderProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files as FileList;
    if (file[0].type !== "text/csv") return onUpload([], "Invalid File type");
    parse(file[0], {
      complete: ({ data }) => {
        e.target.value = "";
        const csvData = data as string[][];
        // Trim last row if empty
        if (csvData[csvData.length - 1][0] === "") csvData.pop();
        onUpload(data as string[][]);
      },
    });
  };

  return (
    <>
      <label className="btn" htmlFor="file-upload" tabIndex={0}>
        <div className="text">UPLOAD CSV FILE</div>
      </label>
      <input
        type="file"
        onChange={onChange}
        id="file-upload"
        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
        tabIndex={-1}
        role="button"
      />
    </>
  );
};
