/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Group } from "components/Layout";
import { SuccessIcon } from "components/AnimatedIcons";
import { AmendType } from "../../AmendVehicles";

const verbMap = {
  activate: "Activation",
  deactivate: "DeActivation",
  update: "Amendment",
};

interface SuccessStepProps {
  amendType: AmendType;
}

export const SuccessStep = ({ amendType }: SuccessStepProps) => {
  return (
    <Group>
      <p>Successfully requested {verbMap[amendType]}.</p>
      <SuccessIcon />
    </Group>
  );
};
