/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { CSSProperties, ReactNode, useMemo } from "react";
import { randomID } from "utils/random";
import styles from "./styles.module.scss";

export interface InputContainerProps {
  label?: string;
  error?: string;
  style?: CSSProperties;
  render: (ID: string) => ReactNode;
}

export const InputContainer = ({ label, error, style = {}, render }: InputContainerProps) => {
  const id = useMemo(() => randomID(), []);
  return (
    <div className={styles.container} style={style}>
      {label && <label htmlFor={id}>{label}</label>}
      {render(id)}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
