/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

interface ValidationRule {
  columnName: string;
  regEx: RegExp;
}

export const vinRegEx = /\w{17}/;
export const booleanRegEx = /^(on|off)$/i;

export const validateCSV = (data: string[][], validationRules: ValidationRule[]) => {
  if (!data.length) return "Invalid CSV file";
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const row = data[rowIndex];
    const vin = row[0];

    const slicedData = data.slice(rowIndex + 1);
    const foundDuplicateVin = slicedData.find((row) => row[0] === vin);
    if (foundDuplicateVin) return `CSV contains duplicate VIN: "${vin}"`;

    for (let columnIndex = 0; columnIndex < validationRules.length; columnIndex++) {
      const cellValue = row[columnIndex].trim();
      const { columnName, regEx } = validationRules[columnIndex];

      if (cellValue.match(regEx) === null) {
        if (columnName === "VIN") {
          return `Row ${rowIndex + 1} has invalid VIN "${vin}"`;
        } else {
          return `"${vin}" has invalid ${columnName} value`;
        }
      }
    }
  }
  return "";
};
