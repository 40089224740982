export const CrossIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_450_980)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.386829 31.2428C0.637185 31.4923 0.977979 31.6326 1.3335 31.6326C1.68901 31.6326 2.02981 31.4923 2.28016 31.2428L16.0002 17.6711L29.7202 31.2428C29.9705 31.4923 30.3113 31.6326 30.6668 31.6326C31.0223 31.6326 31.3631 31.4923 31.6135 31.2428C31.8659 30.9954 32.0079 30.6586 32.0079 30.3073C32.0079 29.956 31.8659 29.6192 31.6135 29.3718L17.8802 15.8132L31.6135 2.25466C32.1363 1.73798 32.1363 0.900279 31.6135 0.383602C31.0907 -0.133076 30.243 -0.133076 29.7202 0.383602L16.0002 13.9553L2.28016 0.383602C1.75733 -0.133076 0.909659 -0.133076 0.386829 0.383602C-0.136 0.900279 -0.136 1.73798 0.386829 2.25466L14.1202 15.8132L0.386829 29.3718C0.134413 29.6192 -0.00756836 29.956 -0.00756836 30.3073C-0.00756836 30.6586 0.134413 30.9954 0.386829 31.2428Z"
          fill="#0C121C"
        />
        <mask id="mask0_450_980" maskUnits="userSpaceOnUse" x="-1" y="-1" width="34" height="33">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.386829 31.2428C0.637185 31.4923 0.977979 31.6326 1.3335 31.6326C1.68901 31.6326 2.02981 31.4923 2.28016 31.2428L16.0002 17.6711L29.7202 31.2428C29.9705 31.4923 30.3113 31.6326 30.6668 31.6326C31.0223 31.6326 31.3631 31.4923 31.6135 31.2428C31.8659 30.9954 32.0079 30.6586 32.0079 30.3073C32.0079 29.956 31.8659 29.6192 31.6135 29.3718L17.8802 15.8132L31.6135 2.25466C32.1363 1.73798 32.1363 0.900279 31.6135 0.383602C31.0907 -0.133076 30.243 -0.133076 29.7202 0.383602L16.0002 13.9553L2.28016 0.383602C1.75733 -0.133076 0.909659 -0.133076 0.386829 0.383602C-0.136 0.900279 -0.136 1.73798 0.386829 2.25466L14.1202 15.8132L0.386829 29.3718C0.134413 29.6192 -0.00756836 29.956 -0.00756836 30.3073C-0.00756836 30.6586 0.134413 30.9954 0.386829 31.2428Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_450_980)"></g>
      </g>
      <defs>
        <clipPath id="clip0_450_980">
          <rect width="32" height="31.6235" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
