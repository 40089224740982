/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useCallback } from "react";
import { AmendFleetData } from "types/provision";

const amendEndpoint = `${process.env.REACT_APP_BASE_API}/batches`;

const defaultOptions: RequestInit = {
  credentials: "include",
};

interface Result<T> {
  data: T;
  status: number;
}

export const useFetch = () => {
  const get = useCallback(
    async <T>(url: string, userOptions: RequestInit = {}): Promise<Result<T>> => {
      const options = { ...defaultOptions, ...userOptions };
      const response = await fetch(url, options);
      const { status, statusText, headers } = response;
      if (status === 500) throw new Error(statusText);
      if (headers.get("Content-Type")) {
        const data = await response.json();
        return { data, status };
      } else {
        return { data: {} as T, status };
      }
    },
    []
  );

  const post = useCallback(
    async <T>(url: string, payload: unknown, userOptions: RequestInit = {}): Promise<Result<T>> => {
      const options = {
        method: "POST",
        body: JSON.stringify(payload),
        ...defaultOptions,
        ...userOptions,
      };
      const response = await fetch(url, options);
      const { status, statusText } = response;
      if (status === 500) throw new Error(statusText);
      const data = await response.json();
      return { data, status };
    },
    []
  );

  const amendVehicle = useCallback(
    (vehicles: AmendFleetData[], desiredStatus: "activate" | "deactivate") => {
      return post(amendEndpoint, { vehicles, desiredStatus });
    },
    []
  );

  return { get, post, amendVehicle };
};
