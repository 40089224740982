/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { Group } from "components/Layout";
import { Button } from "components/Button";
import { AmendType } from "../../AmendVehicles";

const verbMap = {
  activate: "Activate",
  deactivate: "DeActivate",
  update: "Amend",
};

export type AmendMethod = "MANUAL_ENTRY" | "CSV";

interface ChooseAmendMethodStepProps {
  onMethodChoice: (method: AmendMethod) => void;
  amendType: AmendType;
}

export const ChooseAmendMethodStep = (props: ChooseAmendMethodStepProps) => {
  const { onMethodChoice, amendType } = props;

  return (
    <Group>
      <p>
        Please select how you&apos;d like to {verbMap[amendType]} your vehicles. You can
        either&nbsp;
        {verbMap[amendType]} vehicles by manually entering individual VINs or by uploading a CSV
        file.
      </p>
      <Group direction="row">
        <Button text="UPLOAD CSV FILE" testId="csvUpload" onClick={() => onMethodChoice("CSV")} />
        <Button
          text="MANUAL ENTRY"
          testId="manualEntry"
          ghost
          onClick={() => onMethodChoice("MANUAL_ENTRY")}
        />
      </Group>
    </Group>
  );
};
