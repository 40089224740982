/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useContext } from "react";
import { NotificationContext } from "context/notification";

export const useNotification = () => {
  return useContext(NotificationContext);
};
