/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useEffect, useRef } from "react";
import { IconX } from "@tabler/icons";
import type { Notification } from "context/notification";
import styles from "./styles.module.scss";

const displayDuration = 5000;

const typeMap = {
  default: {
    style: "",
    //icon: <IconInfoCircle aria-label="Notification Info" />,
  },
  success: {
    style: styles.success,
    //icon: <IconCheck stroke={3} aria-label="Notification Success" />,
  },
  error: {
    style: styles.error,
    //icon: <IconAlertTriangle stroke={2} aria-label="Notification Error" />,
  },
};

interface NotificationItemProps {
  notification: Notification;
  onRemove: (id: string) => void;
}

export const NotificationItem = ({ notification, onRemove }: NotificationItemProps) => {
  const { id, type, title, body } = notification;
  const { style } = typeMap[type];

  const displayDurationTimeoutId = useRef<number>();

  useEffect(() => {
    displayDurationTimeoutId.current = window.setTimeout(removeItem, displayDuration);
    return () => clearTimeout(displayDurationTimeoutId.current);
  }, []);

  const onMouseEnter = () => {
    clearTimeout(displayDurationTimeoutId.current);
  };

  const onMouseLeave = () => {
    displayDurationTimeoutId.current = window.setTimeout(removeItem, displayDuration / 2);
  };

  const removeItem = () => {
    onRemove(id);
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${style} ${styles.notification}`}
      role="log"
    >
      <div>
        <h5 className={styles.title}>
          {/*{icon} */} {title}
        </h5>
        {body && <p>{body}</p>}
      </div>

      <button
        onClick={removeItem}
        className={styles.close}
        title="Close Notification"
        aria-label="Close Notification"
      >
        <IconX stroke={3} size={15} />
      </button>
    </div>
  );
};
