export const EditIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_509_2580)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2233 22.0043H8.2796L23.1021 6.81739C24.6802 5.25782 24.6802 2.72925 23.1021 1.16968C21.524 -0.389893 18.9653 -0.389893 17.3872 1.16968L0.928931 16.8555C0.335866 17.4194 0.000507955 18.1975 0 19.0109V21.0065C0 22.6598 1.35618 24 3.02912 24H23.2233C23.7809 24 24.233 23.5533 24.233 23.0022C24.233 22.4511 23.7809 22.0043 23.2233 22.0043ZM18.7907 2.60655C19.5797 1.82677 20.8591 1.82677 21.6481 2.60655C22.4372 3.38634 22.4372 4.65062 21.6481 5.43041L8.94601 18.4521L5.61397 15.1592L18.7907 2.60655ZM4.03883 22.0043H3.02912C2.47148 22.0043 2.01941 21.5576 2.01941 21.0065V19.0109C2.02046 18.7393 2.1335 18.4798 2.33242 18.2924L4.15999 16.5462L7.54251 19.8889L5.77553 21.695C5.58589 21.8916 5.32337 22.0033 5.04854 22.0043H4.03883Z"
        />
        <mask id="mask0_509_2580" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2233 22.0043H8.2796L23.1021 6.81739C24.6802 5.25782 24.6802 2.72925 23.1021 1.16968C21.524 -0.389893 18.9653 -0.389893 17.3872 1.16968L0.928931 16.8555C0.335866 17.4194 0.000507955 18.1975 0 19.0109V21.0065C0 22.6598 1.35618 24 3.02912 24H23.2233C23.7809 24 24.233 23.5533 24.233 23.0022C24.233 22.4511 23.7809 22.0043 23.2233 22.0043ZM18.7907 2.60655C19.5797 1.82677 20.8591 1.82677 21.6481 2.60655C22.4372 3.38634 22.4372 4.65062 21.6481 5.43041L8.94601 18.4521L5.61397 15.1592L18.7907 2.60655ZM4.03883 22.0043H3.02912C2.47148 22.0043 2.01941 21.5576 2.01941 21.0065V19.0109C2.02046 18.7393 2.1335 18.4798 2.33242 18.2924L4.15999 16.5462L7.54251 19.8889L5.77553 21.695C5.58589 21.8916 5.32337 22.0033 5.04854 22.0043H4.03883Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_509_2580)"></g>
      </g>
      <defs>
        <clipPath id="clip0_509_2580">
          <rect width="24.2857" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
