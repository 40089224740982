/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useCallback, useContext, useEffect } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, Context } from "context/auth";
import { useFetch } from "./use-fetch";

interface UtilProps {
  context: Context;
  state: unknown;
  navigate: NavigateFunction;
}

interface AuthServerResponse {
  name: string;
  role: string;
}

export const utilities = () => {
  const context = useContext(AuthContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  return { context, state, navigate };
};

const redirectError = (
  error: unknown,
  context: Context,
  navigate: NavigateFunction,
  state: unknown
) => {
  console.error(error);
  context.setState({
    ...context.state,
    isLoading: false,
  });
  navigate("/500", { state });
};

export const getCookieInfo = () => {
  const { get } = useFetch();
  const { context, state, navigate } = utilities();

  useEffect(() => {
    const getCookie = async () => {
      context.setState({
        ...context.state,
        isLoading: true,
      });

      try {
        const { data } = await get<AuthServerResponse>(process.env.REACT_APP_COOKIE_INFO as string);

        if (data.name === "NoCookie" || data.name === "User") {
          context.setState({
            ...context.state,
            isAuthenticated: false,
            isLoading: false,
          });
        } else {
          context.setState({
            ...context.state,
            isAuthenticated: true,
            isLoading: false,
            name: data.name,
            role: data.role,
          });
        }
      } catch ({ message }) {
        redirectError(message, context, navigate, state);
      }
    };

    getCookie();
  }, []);
};

export const useDeleteCookieInfo = (
  { context, state, navigate }: UtilProps,
  setIsClicked: (_: boolean) => void
) => {
  const { get } = useFetch();

  return useCallback(() => {
    context.setState({
      ...context.state,
      isLoading: true,
    });
    const deleteCookie = async () => {
      try {
        const { status } = await get(process.env.REACT_APP_DELETE_COOKIE as string);
        if (status !== 200) throw Error("Something went wrong");
        setIsClicked(false);
        context.setState({
          ...context.state,
          isLoading: false,
          isAuthenticated: false,
        });
      } catch (error) {
        redirectError(error, context, navigate, state);
      }
    };
    deleteCookie();
  }, [context, state]);
};
