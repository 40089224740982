/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ReactNode, useMemo } from "react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

interface ReactQueryContextProviderProps {
  children: ReactNode;
}
export const ReactQueryContextProvider = ({ children }: ReactQueryContextProviderProps) => {
  const queryClient = useMemo(() => {
    const queryCache = new QueryCache({
      onError(e) {
        // Do global error handling
        console.log(e);
      },
    });

    const mutationCache = new MutationCache({
      onError(e) {
        // Do global error handling
        console.log(e);
      },
    });

    return new QueryClient({
      defaultOptions: {},
      queryCache,
      mutationCache,
    });
  }, []);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
