import { DownIcon, UpIcon } from "./";

export const SortIcon = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <UpIcon />
      <DownIcon />
    </div>
  );
};
