/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { CSSProperties, MouseEvent, ReactNode } from "react";

interface ButtonProps {
  text?: string;
  description?: string;
  icon?: ReactNode;
  size?: "md" | "lg";
  color?: "primary" | "secondary" | "danger" | "success";
  ghost?: boolean;
  disabled?: boolean;
  testId?: string;
  style?: CSSProperties;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const Button = (props: ButtonProps) => {
  const {
    text,
    description,
    icon,
    disabled = false,
    size = "md",
    ghost = false,
    color = "primary",
    testId,
    style = {},
    onClick,
  } = props;

  const classNames = ["btn", size, color];
  if (ghost) classNames.push("ghost");

  return (
    <button
      className={classNames.join(" ")}
      onClick={onClick}
      disabled={disabled}
      style={style}
      data-testid={testId}
    >
      {icon && <div className="icon">{icon}</div>}
      <div className="text">
        {text}
        {description && <p className="description">{description}</p>}
      </div>
    </button>
  );
};
