/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import { DownIcon, RightIcon } from "components/icons";
import styles from "./styles.module.scss";

export type AccordionProps = {
  title: string;
  content: string;
};

export const Accordion = ({ title, content }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={styles.accordionTitle} onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        <span>
          {isOpen ? (
            <span title="Accordion Expanded">
              <DownIcon />
            </span>
          ) : (
            <span title="Accordion Closed">
              <RightIcon />
            </span>
          )}
        </span>
      </div>
      <div className={styles.contentBox} aria-expanded={isOpen}>
        <div className={styles.contentText}>{content}</div>
      </div>
    </>
  );
};
