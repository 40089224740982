/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ReactNode, useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import { CrossIcon } from "components/icons";
import styles from "./styles.module.scss";

interface ModalProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  title: string;
  closeOnClickOutside?: boolean;
  icon?: ReactNode;
  children: ReactNode;
}

export const Modal = (props: ModalProps) => {
  const { isOpen, setIsOpen, title, closeOnClickOutside = true, icon, children } = props;

  const backdrop = useRef(null);
  const container = useRef(null);
  const backdropMouseDown = useRef(false);
  const onKeydown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") setIsOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onKeydown, false);
    return () => document.removeEventListener("keydown", onKeydown, false);
  }, []);

  return createPortal(
    <CSSTransition classNames="fade" nodeRef={backdrop} in={isOpen} timeout={250} unmountOnExit>
      <div
        ref={backdrop}
        onMouseDown={(e) => {
          if (e.target === backdrop.current) backdropMouseDown.current = true;
          else backdropMouseDown.current = false;
        }}
        onMouseUp={(e) => {
          if (e.target === backdrop.current && backdropMouseDown.current && closeOnClickOutside)
            setIsOpen(false);
        }}
        className={styles.root}
        aria-label="Modal Backdrop"
      >
        <CSSTransition
          classNames="apear"
          nodeRef={container}
          in={isOpen}
          timeout={250}
          unmountOnExit
        >
          <div className={styles.container} ref={container} role="dialog">
            <div className={styles.header}>
              <h3 className={styles.title}>
                {icon}
                {title}
              </h3>
              <button
                onClick={() => setIsOpen(false)}
                className={styles.close}
                title="Close Modal"
                aria-label="Close Modal"
              >
                <CrossIcon />
              </button>
            </div>
            <div className={styles.content}>{children}</div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>,
    document.body
  );
};
