/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import ReactPaginate from "react-paginate";
import { LeftIcon, RightIcon } from "components/icons";
import { Group } from "components/Layout";
import styles from "./styles.module.scss";

interface PaginationProps {
  total: number;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (page: number) => void;
}

export const Pagination = ({ total, page, setPage, pageSize, setPageSize }: PaginationProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.pagination}>
        <ReactPaginate
          initialPage={page}
          breakLabel="..."
          previousLabel={<LeftIcon />}
          nextLabel={<RightIcon />}
          onPageChange={({ selected }) => setPage(selected)}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={total}
          renderOnZeroPageCount={() => null}
          activeClassName={styles.selected}
          disabledClassName={styles.disabled}
          breakClassName={styles.break}
        />
      </div>

      <label>
        <Group direction="row" gap="sm">
          View per page
          {[5, 10, 30, 60].map((i) => {
            return (
              <button
                key={i}
                className={styles.perPageButtons}
                onClick={() => setPageSize(i)}
                style={i === pageSize ? { textDecoration: "underline" } : {}}
              >
                {i}
              </button>
            );
          })}
        </Group>
      </label>
    </div>
  );
};
