/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useState } from "react";
import { Steps } from "components/Steps";
import { AmendFleetData } from "types/provision";
import { Spinner } from "components/Spinner";
import { AmendMethod } from "./AmendVehiclesSteps/ChooseAmendMethodStep/ChooseAmendMethodStep";
import {
  ChooseAmendMethodStep,
  ConfirmStep,
  CSVStep,
  ManualEntryStep,
  ServerErrorStep,
  SuccessStep,
} from "./AmendVehiclesSteps";
import { useAmendVehiclesApi } from "./use-amend-vehicles-api";
import { ServerError } from "./AmendVehiclesSteps/ServerErrorStep/ServerErrorStep";

type AmendSteps =
  | "CHOOSE_AMEND_METHOD"
  | "AMEND"
  | "CONFIRM"
  | "SUCCESS"
  | "SERVER_ERROR"
  | "LOADING";

export type AmendType = "activate" | "deactivate" | "update";

interface ServerResponsePayload {
  vehiclesRequested: number;
  vehiclesProcessed: number;
  invalidVins: string[];
}

interface AmendVehiclesProps {
  amendType: AmendType;
}

export const AmendVehicles = ({ amendType }: AmendVehiclesProps) => {
  const [step, setStep] = useState<AmendSteps>("CHOOSE_AMEND_METHOD");
  const [amendMethod, setAmendMethod] = useState<AmendMethod>("MANUAL_ENTRY");
  const [serverError, setServerError] = useState<ServerError>({ status: 0, data: [] });
  const [data, setData] = useState<AmendFleetData[]>([]);
  const apiRequest = useAmendVehiclesApi(amendType);

  const onAmendMethodComplete = (method: AmendMethod) => {
    setAmendMethod(method);
    setStep("AMEND");
    if (method !== amendMethod) setData([]);
  };

  const onAmendComplete = (data: AmendFleetData[]) => {
    setData(data);
    setStep("CONFIRM");
  };

  const onConfirm = async () => {
    setStep("LOADING");

    try {
      const { status, data: serverData } = await apiRequest(data);
      if (status !== 200) {
        const serverPayload = serverData as ServerResponsePayload;
        setServerError({ status, data: serverPayload.invalidVins });
        setStep("SERVER_ERROR");
      } else {
        setStep("SUCCESS");
      }
    } catch (e) {
      setServerError({ status: 500, data: [] });
      setStep("SERVER_ERROR");
    }
  };

  const GetCurrentStep = () => {
    switch (step) {
      case "LOADING":
        return <Spinner text="PLEASE WAIT" />;

      case "CHOOSE_AMEND_METHOD":
        return (
          <ChooseAmendMethodStep onMethodChoice={onAmendMethodComplete} amendType={amendType} />
        );

      case "AMEND":
        if (amendMethod === "MANUAL_ENTRY")
          return (
            <ManualEntryStep
              onComplete={onAmendComplete}
              initialData={data}
              amendType={amendType}
              onBack={(data) => {
                setData(data);
                setStep("CHOOSE_AMEND_METHOD");
              }}
            />
          );
        else
          return (
            <CSVStep
              onComplete={onAmendComplete}
              amendType={amendType}
              onBack={() => setStep("CHOOSE_AMEND_METHOD")}
            />
          );

      case "CONFIRM":
        return (
          <ConfirmStep
            data={data}
            onConfirm={onConfirm}
            amendType={amendType}
            onBack={() => setStep("AMEND")}
          />
        );

      case "SERVER_ERROR":
        return <ServerErrorStep serverError={serverError} onRetry={onConfirm} />;

      case "SUCCESS":
        return <SuccessStep amendType={amendType} />;
    }
  };

  return (
    <Steps step={step}>
      <GetCurrentStep />
    </Steps>
  );
};
