/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { FormEvent, useCallback, useState } from "react";

type Errors<T> = Partial<Record<keyof T, string>>;

interface Validation {
  required?: boolean;
  min?: number;
  max?: number;
}

type Validations<T> = Partial<Record<keyof T, Validation>>;

interface useFormProps<T> {
  defaultValues: T;
  validations?: Validations<T>;
}

const validateField = (value: unknown, validation: Validation) => {
  const { required, min, max } = validation;
  if (required && !value) return "This field is required";
  if (min && typeof value === "string" && value.length < min)
    return `Must be at least ${min} characters`;
  if (max && typeof value === "string" && value.length > max)
    return `Must not be larger that ${max} characters`;
  return "";
};

export const useForm = <T>({ defaultValues, validations }: useFormProps<T>) => {
  const [formValues, setFormValues] = useState<T>(() => defaultValues);
  const [errors, setErrors] = useState<Errors<T>>(() => ({}));

  const onChange = useCallback(
    (key: keyof T) => {
      return (newValue: ValueOf<T>) => {
        const validation = validations?.[key];
        if (validation) {
          const validationResult = validateField(newValue, validation);
          setErrors((errors) => ({
            ...errors,
            ...{ [key]: validationResult },
          }));
        }
        setFormValues((formValues) => ({
          ...formValues,
          ...{ [key]: newValue },
        }));
      };
    },
    [validations]
  );

  const validate = useCallback(() => {
    let isValid = true;
    if (validations) {
      for (const key in validations) {
        const value = formValues[key];
        const validation = validations?.[key];
        if (!validation) continue;
        const validationResult = validateField(value, validation);
        if (validationResult) {
          isValid = false;
          setErrors((errors) => ({
            ...errors,
            ...{ [key]: validationResult },
          }));
        }
      }
    }
    return isValid;
  }, [validations, formValues]);

  const onSubmit = useCallback(
    (callback: (formValues: T) => void) => {
      return (e: FormEvent) => {
        e.preventDefault();
        if (validate()) callback(formValues);
      };
    },
    [formValues]
  );

  return { onChange, onSubmit, formValues, errors, validate };
};
