/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useSearchParams } from "react-router-dom";
import { Group } from "components/Layout";
import styles from "./styles.module.scss";

export function Login() {
  const [params] = useSearchParams();

  let loginURL =
    `${process.env.REACT_APP_LOGIN_URL}` +
    `?${process.env.REACT_APP_REDIRECT_URI}` +
    `&${process.env.REACT_APP_CLIENT_ID}` +
    `&${process.env.REACT_APP_RESPONSE_TYPE}` +
    `&${process.env.REACT_APP_STATE}` +
    `&${process.env.REACT_APP_SCOPE}`;

  if (params.get("org")) {
    loginURL += `&org=${params.get("org")}`;
  }

  return (
    <section className={styles.background}>
      <div>
        <div className={styles.container}>
          <h1 className={styles.title}>FLEET PORTAL</h1>
          <div className={styles.loginBox}>
            <Group>
              <h3>WELCOME</h3>
              <a className="btn" href={loginURL} style={{ width: "100px" }}>
                <div className="text">GET STARTED</div>
              </a>
            </Group>
          </div>
        </div>
      </div>

      <div></div>
    </section>
  );
}
