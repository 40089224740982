/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ErrorPage } from "./ErrorPage";

type FallbackProps = {
  error: {
    message: string;
  };
};

export const ErrorFallback = ({ error }: FallbackProps) => {
  return <ErrorPage message={error.message} />;
};

export const InternalServerError = () => {
  return <ErrorPage message="INTERNAL SERVER ERROR." />;
};

export const NotFoundError = () => {
  return <ErrorPage message="PAGE NOT FOUND." />;
};
