/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useEffect, useRef, useState } from "react";
import { Select } from "components/Form";
import { Group, Item } from "components/Layout";

const brandOptions = [
  { name: "All", value: "all" },
  { name: "Jaguar", value: "Jaguar" },
  { name: "Land Rover", value: "Land Rover" },
];

const statusOptions = [
  { name: "All", value: "all" },
  { name: "Active", value: "true" },
  { name: "Deactive", value: "false" },
];

const subOptions = [
  { name: "All", value: "all" },
  { name: "Geolocation", value: "Geolocation" },
  { name: "Health", value: "Health" },
];

const inControlOptions = [
  { name: "All", value: "all" },
  { name: "Enabled", value: "true" },
  { name: "Disabled", value: "false" },
];

const formatOptionValue = (value: string) => {
  if (value === "all") return "all";
  return value === "true" ? true : false;
};

export type Filter = Record<string, string | boolean>;

interface VehiclesTableFiltersProps {
  onChange: (filter: Filter) => void;
}

export const VehiclesTableFilters = ({ onChange }: VehiclesTableFiltersProps) => {
  const firstUpdate = useRef(true);
  const [brand, setBrand] = useState("all");
  const [status, setStatus] = useState("all");
  const [subscriptions, setSubscriptions] = useState("all");
  const [inControl, setInControl] = useState("all");

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    let filter: Filter = {
      brand,
      subscriptions,
      vehicleActivated: formatOptionValue(status),
      inControlDisabled: formatOptionValue(inControl),
    };

    // Remove fields with 'All' value from 'filter' object
    filter = Object.keys(filter).reduce((newFilter, key) => {
      if (filter[key] !== "all") newFilter[key] = filter[key];
      return newFilter;
    }, {} as Filter);

    onChange(filter);
  }, [brand, status, subscriptions, inControl]);

  return (
    <Group direction="row" gap="sm">
      <Item>
        <Select label="Brand" value={brand} options={brandOptions} onChange={setBrand} />
      </Item>

      <Item>
        <Select
          label="Vehicle status"
          value={status}
          options={statusOptions}
          onChange={setStatus}
        />
      </Item>

      <Item>
        <Select
          label="Subscriptions"
          value={subscriptions}
          options={subOptions}
          onChange={setSubscriptions}
        />
      </Item>

      <Item>
        <Select
          label="InControl"
          value={inControl}
          options={inControlOptions}
          onChange={setInControl}
        />
      </Item>
    </Group>
  );
};
