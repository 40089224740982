/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { useEffect, useRef, useState } from "react";
export const useClickOutsideRef = () => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 ? true : false);
  /*
    The button will close when the user clicks outside of the
    div reference in navLogout only when the button has been opened.
  */
  useEffect(() => {
    function handleClickOutsideRef(event: MouseEvent) {
      const target = event.target as HTMLDivElement;
      if (buttonRef.current && !buttonRef.current.contains(target)) {
        setIsOpen(!isOpen);
      }
    }
    if (isOpen) {
      document.addEventListener("click", handleClickOutsideRef);
    }
    return () => {
      document.removeEventListener("click", handleClickOutsideRef);
    };
  }, [isOpen, buttonRef]);
  /*
    This button is fired when clicking the username.
    This button is only for opening and closing the log out button.
  */
  function handleDropdownClick() {
    setIsOpen(!isOpen);
  }
  /*
    This checks if the screen is equal to or less than 768px.
  */
  useEffect(() => {
    const mediaQuery = window.matchMedia("screen and (max-width: 768px)");
    function handleMobileView(event: MediaQueryListEvent) {
      if (event.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    mediaQuery.addEventListener("change", handleMobileView);
    return () => {
      mediaQuery.removeEventListener("change", handleMobileView);
    };
  }, []);

  return { handleDropdownClick, setIsOpen, buttonRef, isOpen, isMobile };
};
