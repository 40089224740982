/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

export interface Parser<T> {
  key: keyof T;
  resolver: (value: string) => ValueOf<T>;
}

export const parseBoolean = (cellValue: string) => {
  return cellValue.match(/on/i) ? true : false;
};

export const parseCSV = <T>(data: string[][], parseMap: Parser<T>[]): T[] => {
  return data.map((dataRow) => {
    const rowParsed = {} as T;
    parseMap.forEach(({ key, resolver }, index) => {
      const cellValue = dataRow[index];
      rowParsed[key] = resolver(cellValue);
    });
    return rowParsed;
  });
};
