/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { TickIcon } from "components/icons";
import styles from "./styles.module.scss";

export const SuccessIcon = () => (
  <div className={styles.container}>
    <div className={styles.success} title="Success">
      <TickIcon />
    </div>
  </div>
);
